/* ----- General CSS ------ */
html{
  font-size: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(bgground.jpg);
  color: aliceblue ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------------ Main Classnames ------------- */

.nav{
  display: flex;
  color: aliceblue;
  width: 100%;

}

.nav ul {
  display: flex;
  list-style: none;
  padding: 2 2rem;
  
}
.nav a {
  color: inherit;
  text-decoration: none ;
  height: 100%;
  display: flex;
  padding: 2rem;
}
.nav li{
  font-size:x-large;
}
.nav li :hover{
  color:#905083;
  transform: scale(1.2); 

}

.active{
  color:#905083;

}

.page{
  color: aliceblue;
  margin: 2%;
  font-size: 1.5rem;

}
.s1-page{
  width: 70%;
  float: left;
  text-align: justify;
}

.s2-page{
  width: 30%;
  float: right;

}


.drone{
  margin: 4%;
}

.logo {
  fill: hsl(300, 69%, 71%) !important;
}
/*-------------- Experience Classnames ----------*/
.MuiTimeline-root{
  padding-top: 4rem !important;
}
.MuiTimelineSeparator-root {
  color: #905083;
}
.MuiTimelineConnector-root {
  color: #905083;
  background-color: #905083 !important;
  height: 50px;
}

.MuiPaper-root{
  color: aliceblue !important;
  /* background-color: transparent !important; */
  background-color: rgba(0, 0, 0, 0.3) !important;
  height: 100px;
  padding: 1rem;
}

/* ------------ Contato Classnames ------------- */
.contato{
  color: aliceblue;
  flex-direction: column;
  padding: 0.5rem;

}
.contato div{
  display: grid;
  grid-template-columns: max-content;
  place-content: center;
  padding: 0.5rem;

}

.contato h1{
  text-align: center;
}
.contato input{
  width: 18.5rem;
  height: 1.5rem;
}
.contato textarea{
  padding: 0.5rem;
  height: 8rem;
  width: 18rem;
}
.contato button{
  width: 11rem;
  padding: 0.5rem;
  color:aliceblue;
  font-weight: bold;
  background-color: #3FB950;
  border: #3FB950;
  border-radius: 0.2rem;
}


/* ------------ Artigos Classnames ------------- */

.artigos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 4rem;

}


.artigos span{
  width: 14rem;
  text-align: justify;
}

.artigos img{
  border-radius: 0.8rem;
  height: 14rem;
  width: 18rem;
  object-fit: cover;
  top: 0 px;

}
.artigos a {
  color: inherit;
  text-decoration: none ;
  height: 100%;
  padding: 2rem;

}

.artigos li {
  list-style: none;
}

.card{
  width: 18rem;
  text-align: justify;
}

.title{
  font-size: 1.5rem;
  font-style:oblique;
  font-weight: bold;
  color: #3FB950;
}

/* ------------ Livros Classnames ------------- */

.livros {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 4rem;

}


.livros span{
  width: 14rem;
  text-align: justify;
}

.livros img{
  border-radius: 0.8rem;
  width: 18rem;
  object-fit: cover;
  top: 0 px;

}
.livros a {
  color: inherit;
  text-decoration: none ;
  height: 100%;
  padding: 2rem;

}

.livros li {
  list-style: none;
}

.card-livro{
  width: 18rem;
  text-align: justify;
}

.title-livro{
  font-size: 1.5rem;
  font-style:oblique;
  font-weight: bold;
  color: #3FB950;
}


/* ------------- Media Queries -------------*/


@media only screen and (max-width: 500px) {
  body {
    display: flex;
    flex-wrap: wrap;
  }
  .page{
    flex-direction: column;
  }
  .s2-page img {
    order: -1;
  }
}